import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { LinkedIn, Instagram, YouTube } from '@mui/icons-material';
import styles from './Footer.module.css'; // Import CSS

function Footer() {
  return (
    <Box sx={{ backgroundColor: '#1c1c1c', color: 'white', padding: '50px' }}>
      <Grid container spacing={3}>
        {/* Explore Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Explore</Typography>
          <ul className={styles.footerList}>
            <li><Link to="/create-course" className={styles.footerLink}>Teach on LCM</Link></li>
            <li><Link to="/about-us" className={styles.footerLink}>About us</Link></li>
            <li><Link to="/features" className={styles.footerLink}>Features</Link></li>
          </ul>
        </Grid>

        {/* Support Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Support</Typography>
          <ul className={styles.footerList}>
            <li><Link to="/contact-us" className={styles.footerLink}>Contact Us</Link></li>
          </ul>
        </Grid>

        {/* Legal Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Legal</Typography>
          <ul className={styles.footerList}>
            <li><Link to="/terms" className={styles.footerLink}>Terms</Link></li>
            <li><Link to="/privacy-policy" className={styles.footerLink}>Privacy policy</Link></li>
            <li><Link to="/instructor-terms" className={styles.footerLink}>Instructor Terms</Link></li>
          </ul>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom align="center">Follow us</Typography>
          <Box className={styles.socialIcons} sx={{ textAlign: 'center', marginTop: '10px' }}>
            <IconButton href="https://www.linkedin.com/company/lcmgo" target="_blank" color="inherit">
              <LinkedIn />
            </IconButton>
            {/* <IconButton href="https://www.facebook.com" target="_blank" color="inherit">
              <Facebook />
            </IconButton> */}
            <IconButton href="https://www.youtube.com/@lcmgo" target="_blank" color="inherit">
              <YouTube />
            </IconButton>
            <IconButton href="https://www.instagram.com/lcm_go" target="_blank" color="inherit">
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Bottom Section with Logo and Copyright */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginTop: '30px',
          paddingTop: '10px',
          borderTop: '1px solid #444',
        }}
      >
        {/* Logo at Bottom Left */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="/lcm-logo.png" alt="LCMGO Logo" style={{ width: '100px', height: 'auto' }} />
        </Box>

        {/* Copyright at Bottom Right */}
        <Typography variant="body2"> Copyright © 2025 LCM. All Rights Reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
