import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import Loading from "./components/Layout/Loading";
import NotFound from "./components/Layout/NotFound";
import ChatIcon from "./components/Layout/ChatIcon";
import ScrollToTop from "./components/Layout/ScrollToTop";

const Home = lazy(() => import("./components/Home/Home"));
const Login = lazy(() => import("./components/Auth/Login"));
const LoginGoogle = lazy(() => import("./components/Auth/GoogleLogin"));
const AuthSuccess = lazy(() => import("./components/Auth/AuthSuccess"));
const CourseList = lazy(() => import("./components/CoursesLandingPage/CourseList"));
const CreateCourse = lazy(() => import("./components/CoursesCreation/CreateCourse"));
const CourseDetail = lazy(() => import("./components/CoursesLandingPage/CourseDetail"));
const ForgotPassword = lazy(() => import("./components/Auth/ForgotPassword"));
const ResetPasswordWithCode = lazy(() => import("./components/Auth/ResetPasswordWithCode"));
const MyLearnings = lazy(() => import("./components/User/MyLearnings"));
const MyTeachings = lazy(() => import("./components/User/MyTeachings"));
const EditCourse = lazy(() => import("./components/CoursesCreation/EditCourse"));
const LCMFeatures = lazy(() => import("./components/CoursesCreation/LCMFeatures"));
const CourseStructurePage = lazy(() => import("./components/CoursesLMS/CourseStructurePage"));
const EditOrderPage = lazy(() => import("./components/CoursesLMS/EditOrderPage"));
const LiveStream = lazy(() => import("./components/LiveStream/LiveStream"));
const Pricing = lazy(() => import("./components/Pricing/Pricing"));
const SubscriptionPlans = lazy(() => import("./components/Pricing/SubscriptionPlans"));
const MyProfile = lazy(() => import("./components/User/MyProfile"));
const SearchResults = lazy(() => import("./components/Search/SearchResults"));
const InstructorTerms = lazy(() => import("./components/CoursesCreation/InstructorTerms"));
const BankDetailsPage = lazy(() => import("./components/User/BankDetailsPage"));
const PasswordResetSuccess = lazy(() => import("./components/Auth/PasswordResetSuccess"));
const RegisterSuccess = lazy(() => import("./components/Auth/RegisterSuccess"));
const RegisterReferralSuccess = lazy(() => import("./components/Auth/RegisterReferralSuccess"));
const PrivacyPolicy = lazy(() => import("./components/Layout/PrivacyPolicy"));
const AboutUs = lazy(() => import("./components/Layout/AboutUs"));
const ContactUs = lazy(() => import("./components/Layout/ContactUs"));
const Terms = lazy(() => import("./components/Layout/Terms"));
const Referral = lazy(() => import("./components/User/ReferralDiscount"));
const MatrixChat =lazy(()=> import("./components/chat/MatrixChat"))
const Css =lazy(()=> import("./components/Home/CSSCamelCaseConverter"))
const AssignmentList = lazy(() => import("./components/Assignments/AssignmentList"));
const InstructorAssignmentDetail= lazy(() => import("./components/Assignments/InstructorAssignmentDetails"))
const StudentAssignmentDetails = lazy(() => import("./components/Assignments/StudentAssignementDetails"))
const CreateAssignment = lazy(() => import("./components/Assignments/CreateAssignment"));
const EditAssignment = lazy(() => import("./components/Assignments/EditAssignments"));
const QuizList = lazy(() => import("./components/Quizes/QuizList"));
const EditQuiz = lazy(() => import("./components/Quizes/EditQuiz")); 
const CheckoutPage= lazy(() =>import("./components/Payment/CheckoutPage"));
const StartInstructorChat = lazy(() => import("./components/CoursesLandingPage/StartInstructorChat"));
function AppRoutes() {
  const location = useLocation();

  // Hide Navbar and Footer on specific routes
  const hideHeaderFooterRoutes = ["/my-chat"];
  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  const pagesWithChatIcon = ["/my-learnings", "/my-teachings"];
  const dynamicPathsWithChat = ["/lms"]; // Add all dynamic patterns here

  const showChatIcon =
    pagesWithChatIcon.includes(location.pathname) ||
    dynamicPathsWithChat.some(path => location.pathname.includes(path));

  return (
    <><ScrollToTop />
      {!shouldHideHeaderFooter && <Navbar />}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login-temporary" element={<Login />} />
          <Route path="/login" element={<LoginGoogle />} />
          <Route path="/auth-success" element={<AuthSuccess />} />
          <Route path="/courses" element={<CourseList />} />
          <Route path="/create-course" element={<CreateCourse />} />
          <Route path="/features" element={<LCMFeatures />} />
          <Route path="/courses/:courseId" element={<CourseDetail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password-with-code" element={<ResetPasswordWithCode />} />
          <Route path="/my-learnings" element={<MyLearnings />} />
          <Route path="/my-teachings" element={<MyTeachings />} />
          <Route path="/courses/edit/:courseId" element={<EditCourse />} />
          <Route path="/courses/:courseId/lms" element={<CourseStructurePage />} />
          <Route path="/courses/:courseId/edit-order" element={<EditOrderPage />} />
          <Route path="/live/:roomId/:role" element={<LiveStream />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/instructor-terms" element={<InstructorTerms />} />
          <Route path="/payout-settings" element={<BankDetailsPage />} />
          <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
          <Route path="/register-success" element={<RegisterSuccess />} />
          <Route path="/register-referral-success" element={<RegisterReferralSuccess />} />
          <Route path="/referral-discount" element={<Referral />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/my-chat" element={<MatrixChat />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/css" element={<Css />} />
          <Route path="/courses/:courseId/assignmentList" element={<AssignmentList/>} />
          <Route path="/assignment/:assignmentId/instructor" element={<InstructorAssignmentDetail/>} />
          <Route path="/assignment/:assignmentId/learner" element={<StudentAssignmentDetails/>} />
          <Route path="/create-assignment" element={<CreateAssignment />} />
          <Route path="/editAssignments/:assignmentId" element={<EditAssignment />} />
          <Route path="/courses/:courseId/QuizList" element={<QuizList/>} />
          <Route path="/editQuiz/:quizId" element={<EditQuiz />} />
          <Route path="/checkout/:courseId" element={<CheckoutPage/>} />
          <Route path="/start-instructor-chat/:courseId" element={<StartInstructorChat />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {!shouldHideHeaderFooter && <Footer />}
      {showChatIcon && <ChatIcon />}
    </>
  );
}

function App() {
  useEffect(() => {
    const checkTokenExpiry = () => {
      const tokenExpiry = localStorage.getItem("tokenExpiry");
      if (tokenExpiry && Date.now() > parseInt(tokenExpiry, 10)) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
        window.location.reload();
      }
    };

    const interval = setInterval(checkTokenExpiry, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
